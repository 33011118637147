export default {
  init() {
    var timeout;
    $('.category-filter').click(function(){
      var selectedCategory = $(this).text();

      $('.category-filter').css('opacity', 0.25);
      $(this).css('opacity', 1);

      var allInvisible = true;

      $('.our-clients .logo-wrapper').each(function(){
        var categories = $(this).data('categories');
        var hasCategory = false;
        categories.forEach(category => {
          if(category == selectedCategory){
            hasCategory = true;
          }
        });

        if(hasCategory) {
          $(this).css('opacity', 1);
          if($(this).visible()){
            allInvisible = false;
          }
        }else {
          $(this).css('opacity', 0.25);
        }
      });

      if(allInvisible){
        scroll.scrollTo('.our-clients', {
          offset: 300,
        });
      }

      clearTimeout(timeout);
      timeout = setTimeout(function(){
        $('.our-clients .logo-wrapper').css('opacity', 1);
        $('.category-filter').css('opacity', 1);
      }, 6000);
    });

    $('.accordion-items .item').each(function(){
      $(this).click(function(){
        $(this).find('.plus').toggleClass('open');
        $(this).siblings().find('.plus').removeClass('open');

        $(this).find('.accordion-content').slideToggle();
        $(this).siblings().find('.accordion-content').slideUp();
      })
    });

    //start playing all videos
    if($('.startup-sprint-section video').get(0)){
      $('.startup-sprint-section video').get(0).play();
    }

    $('.content').imagesLoaded(function(){
      scroll.update();
    });
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
