import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Autoplay]);

export default {
  init() {
    // JavaScript to be fired on the home page
    new Swiper('.carousel-items', {
      touchStartPreventDefault: false,
      slidesPerView: 1,
      direction: 'horizontal',
      loop: false,
      centeredSlides: false,
      freeMode: false,
      spaceBetween: 0,
      allowTouchMove: false,
      autoplay: {
        delay: 1000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      breakpoints: {
        960: {
          slidesPerView: 5,
          direction: 'vertical',
          spaceBetween: 30,
          loop: true,
          centeredSlides: true,
        },
      },
    });

    // var angle = 360 / $('.vertical-carousel .item').length;
    // $('.vertical-carousel .item').each(function(key){
    //   console.log(key);
    //   console.log(angle * key);
    //   $(this).css('transform', 'rotateX('+(angle * key)+'deg) translateZ(200px) translateY(-50%)');
    // });

    $('.content').imagesLoaded(function(){
      scroll.update();
    });

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
