import panzoom from 'panzoom/dist/panzoom';

var isMobile;
var isClicking = false;

export default {
  init() {
    checkIsMobile();
    // JavaScript to be fired on the about us page
    if(!isMobile) {
      scroll.stop();
    }

    var multiplier = 0;
    var count = 0;

    // if($('.template-projects video').get(0)){
    //   $('.template-projects video').get(0).play();
    // }

    $('.projects .project-wrapper').each(function(){      
      if(count == 4){
        count = 0;
        multiplier++;
      }
      count++;

      if(!isMobile) {
        $(this).css('transform', 'translate('+(59 * multiplier)+'%, '+(3 * multiplier)+'%)');
      }
      $(this).css('opacity', 1);
    });

    if(!isMobile) {
      var zoomElement = document.querySelector('#projects-scene');
      var zoomScene = panzoom(zoomElement, {
        transformOrigin: {x: 0.6, y: 0.5},
        maxZoom: 7,
        minZoom: 0.5,
        // initialZoom: 2,
        initialX:0.5,
        initialY:10.5,
        bounds: true,
        boundsPadding: 0,
        onTouch: function() {
        },
        // beforeMouseDown: function(e) {
        //   // allow mouse-down panning only if altKey is down. Otherwise - ignore
        //   var shouldIgnore = !e.altKey;
        //   return shouldIgnore;
        // },
      });
  
  
      document.body.onmousedown = function() { 
        isClicking = true;
      }
  
      document.body.onmouseup = function() {
        isClicking = false;
      }
  
      zoomScene.on('pan', function() {
        if(!isMobile && isClicking){
          $('.project-wrapper .project').css('pointer-events', 'none');
        }
      });
  
      zoomScene.on('panend', function() {
        if(!isMobile){
          $('.project-wrapper .project').css('pointer-events', 'unset');
        }
      });
  
      $('.buttons .zoom-in').click(function(){
        var sceneWidth = $('.scene-parent').width();
        var sceneHeight = $('.scene-parent').height();
        zoomScene.smoothZoom(sceneWidth/2, sceneHeight/2, 2);
      });
  
      $('.buttons .zoom-out').click(function(){
        var sceneWidth = $('.scene-parent').width();
        var sceneHeight = $('.scene-parent').height();
        zoomScene.smoothZoom(sceneWidth/2, sceneHeight/2, 0.5 );
      });
  
      scroll.update();
    }

    $(window).resize(function() {
      //resize just happened, pixels changed

      //responsiveness
      checkIsMobile();
    });
  },
  finalize(){
    
  },
};

function checkIsMobile() {
  var width = $(window).width();
  if (width <= 960) {
    //code for mobile devices
    isMobile = true;
  } else {
    //code for other devices
    isMobile = false;
  }
}
