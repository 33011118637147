import LocomotiveScroll from 'locomotive-scroll';
import { Vector3, Vector2, WebGLRenderer, PerspectiveCamera, Clock, Scene, Object3D, SphereBufferGeometry, MeshPhysicalMaterial, Mesh, SpotLight } from 'three/build/three.module';
import vertexShader from '../shaders/vertex.glsl';
import fragmentShader from '../shaders/fragment.glsl';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import gsap from 'gsap';
// import * as dat from 'dat.gui/build/dat.gui.module';

var isMobile;
let camera;
let blob1;
let blob2;
let clock;
let renderer;
let rendererSize;
let scene;
let scene2;
let cameraY;
let cameraZ;

let blob1Position;

const gsapEase = 'elastic.out(1, 0.75)';
const gsapDuration = 5;

// let orbitControls;
// let enableDebugging = false;
// let gui;

let cursorLagWrapper;
let cursorDot;
let cursorInfoWrapper;

var hideMagicCursor = false;

var lastmousex=-1; 
var lastmousey=-1;
var mousespeedx = 0;
var mousespeedy = 0;

function moveMagicCursor(e) {
  var cursorLagWidth = $('.cursor-lag').outerWidth();
  var cursorLagHeight = $('.cursor-lag').outerHeight();
  var cursorDotWidth = $('.cursor-dot').outerWidth();
  var cursorDotHeight = $('.cursor-dot').outerHeight();
  var cursorInfoWidth = $('.cursor-info').outerWidth();
  var cursorInfoHeight = $('.cursor-info').outerHeight();
  var mousex = e.pageX;
  var mousey = e.pageY;
  if(cursorInfoWidth == 0) {
    cursorInfoWidth = 122;
    cursorInfoHeight = 122;
  }

  //calculate mouse speed
  if (lastmousex > -1) {
    mousespeedx = Math.abs(mousex - lastmousex);
    mousespeedy = Math.abs(mousey - lastmousey);
  }

  lastmousex = mousex;
  lastmousey = mousey;

  if(!hideMagicCursor){
    cursorLagWrapper.setAttribute('style', 'transform: translate('+(mousex-(cursorLagWidth / 2))+'px, '+(mousey-(cursorLagHeight / 2))+'px) scale(1.'+mousespeedx+', 1.'+mousespeedy+')');
    cursorDot.setAttribute('style', 'transform: translate('+(mousex-(cursorDotWidth/2))+'px, '+(mousey-(cursorDotHeight/2))+'px)');
    cursorInfoWrapper.setAttribute('style', 'transform: translate('+(mousex-(cursorInfoWidth/2))+'px, '+(mousey-(cursorInfoHeight/2))+'px) scale(1.'+(Math.abs(mousespeedx/2).toFixed())+', 1.'+(Math.abs(mousespeedy/2).toFixed())+')');
    // cursorLag.setAttribute('style', 'left: '+(mousex-(cursorLagWidth / 2))+'px; top:'+(mousey-(cursorLagHeight / 2))+'px');
    // cursorDot.setAttribute('style', 'left: '+(mousex-2.5)+'px; top:'+(mousey-2.5)+'px');
  }else{
    cursorLagWrapper.setAttribute('style', 'transform: translate('+(mousex-(cursorLagWidth / 2))+'px, '+(mousey-(cursorLagHeight / 2))+'px); display: none;');
    cursorDot.setAttribute('style', 'transform: translate('+(mousex-(cursorDotWidth/2))+'px, '+(mousey-(cursorDotHeight/2))+'px); display: none;');
    cursorInfoWrapper.setAttribute('style', 'transform: translate('+(mousex-(cursorInfoWidth/2))+'px, '+(mousey-(cursorInfoHeight/2))+'px); display: none;');
  }
}

function hoverDetection(){
  //detect hover link
  $('.hover, .underline-animated, .link-line-animated, a').on('mouseover', function(){
    $('.cursor-lag, .cursor-dot').addClass('hovering');
  });

  $('.hover, .underline-animated, .link-line-animated, a').on('mouseout', function(){
    $('.cursor-lag, .cursor-dot').removeClass('hovering');
  });

  //detect info hover
  $('[data-cursor-info]').on('mouseover', function(){
    $('.cursor-info').addClass('showing');
    $('.cursor-info').html($(this).data('cursor-info'));
    $('.cursor-info').removeClass('medium large');
    $('.cursor-info').addClass($(this).data('cursor-size'));
  });

  $('[data-cursor-info]').on('mouseout', function(){
    $('.cursor-info').removeClass('showing');
  });
}

export default {
  containerInit(){
    //tiltjs
    $('[data-tilt]').tilt();

    scroll.start();

    hoverDetection();
    
    // if(enableDebugging){
    //   orbitControls = new OrbitControls( camera, renderer.domElement );
    // }
    // camera.lookAt( 0, 0, 0 );

    blob1Position = new Vector3(2, -0.57, 0);
    if(isMobile) {
      // blob1Position = new Vector3(1.5, -0.57, 3);
    }
    blob1.position.set(blob1Position.x, blob1Position.y, blob1Position.z);
    blob1.rotation.set(0, -0.34, 0);

    blob2.position.set(-6.5, 1.5, -5);
    if(isMobile) {
      // blob2.position.set(-2.0, -2.5, -6);
    }
    blob2.rotation.set(0, -0.34, 0);

    // const blobs = [blob1];

    $(window).resize(function() {
      //resize just happened, pixels changed
      renderer.getSize(rendererSize);
      camera.aspect = window.innerWidth / rendererSize.y;
      camera.updateProjectionMatrix();
      renderer.setSize( $(window).width(), rendererSize.y );
      renderer.setPixelRatio(window.devicePixelRatio);

      //responsiveness
      var width = $(window).width();
      if (width <= 960) {
        //code for mobile devices
        isMobile = true;
      } else {
        //code for other devices
        isMobile = false;
      }

      //viewport height
      document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
    });

    scroll.on('scroll', (args) => {
      if(typeof args.currentElements['rocket-wrapper'] === 'object') {
        let progress = args.currentElements['rocket-wrapper'].progress;
        $('.rocket-wrapper .rocket').css('transform', 'translate(-'+(progress*200)+'px, -'+(progress*200)+'px)');
        $('.rocket-wrapper .smoke').css('transform', 'translate(-'+(progress*100)+'px, -'+(progress*100)+'px)');
      }

      var scrollLimit = args.limit.y;
      var currentScroll = args.scroll.y;
      if($('body').hasClass('template-studio')){
        //if studio move blob1
        var blob1ToMove = blob1Position.x + (currentScroll * 0.002);
        if(blob1ToMove < 3.5){
          gsap.to(blob1.position, {duration: 5,x:blob1ToMove, y: blob1Position.y, z: blob1Position.z, ease: gsapEase });
        }
      }

      if(!$('body').hasClass('template-projects') && !$('body').hasClass('single-project') && !$('body').hasClass('error404')){
        //move camera down with scroll
        gsap.to(camera.position, {duration: 5,x:(camera.position.x), y: (((currentScroll * -1.5) / scrollLimit) + cameraY), z: ((currentScroll * -1.5) / scrollLimit) + cameraZ, ease: gsapEase });
      }

      //bring blob1 closer
      // var positionZ = ((currentScroll * 2) / scrollLimit);
      // blob1.position.z = positionZ;

      //animate tapes on mobile
      if(isMobile){
        for(var key in args.currentElements){
          if(Object.values(args.currentElements[key].el.classList).indexOf('sliding-tape-container') > -1){
            var progress = args.currentElements[key].progress;
            var tapesElement = args.currentElements[key].el;
            $(tapesElement).find('.sliding-tape').each(function(key){
              var tape = $(this);
              if((key % 2) == 0){
                tape.find('div').css('transform', 'translateX(-'+(progress*500)+'px)');
              }else{
                tape.find('div').css('transform', 'translateX('+(progress*500)+'px)');
              }
            });
          }
        }
      }
    });

    $('#meeting-popup').click(function(){
      hideMeetingPopup();
    });

    $('#meeting-popup .popup-content').click(function(e){
      e.stopPropagation();
    });

    $('#meeting-popup .close-popup').click(function(){
      hideMeetingPopup();
    });

    $('.open-meeting-popup').click(function(){
      showMeetingPopup();
    });

    function hideMeetingPopup(){
      $('#meeting-popup').removeClass('show');
      setTimeout(() => {
        $('#meeting-popup').hide();
      }, 500);
    }

    function showMeetingPopup(){
      $('#meeting-popup').css('display', 'flex');
      setTimeout(() => {
        $('#meeting-popup').addClass('show');
      }, 0);
    }

    //imagesLoaded
    $('.single-project').imagesLoaded(function(){
      scroll.update();
      
      //tiltjs
      $('[data-tilt]').tilt();
    });

    //pageLoaded
    $(document).ready(function () {
      scroll.update();
    });

    //load iframe booking
    function deferIframe() {
      var iframeElem = document.getElementsByTagName('iframe');
      for ( var i = 0; i < iframeElem.length; i++ ) {
        if(iframeElem[i].getAttribute('data-src')) {
          iframeElem[i].setAttribute('src',iframeElem[i].getAttribute('data-src'));
        } 
      } 
    }
    setTimeout(() => {
      deferIframe();
    }, 2000);
    // window.onload = deferIframe;

    //menu mobile
    var mobileMenu = $('header .mobile-menu');
    var isOpening = false;
    var isClosing = false;
    $('header .open-menu').click(function(){
      $(this).toggleClass('open');

      if(mobileMenu.hasClass('open')){
        //closing
        mobileMenu.removeClass('open');
        $('body').css('overflow', 'unset');
        isClosing = true;
        isOpening = false;
        setTimeout(() => {
          if(isClosing){
            mobileMenu.hide();
          }
          isClosing = false;
        }, 500);
      }else{
        //opening
        mobileMenu.show();
        $('body').css('overflow', 'hidden');
        isOpening = true;
        isClosing = false;
        setTimeout(() => {
          if(isOpening){
            mobileMenu.addClass('open');
          }
          isOpening = false;
        }, 0);
      }
    });

    $('header .mobile-menu .menu-item, header .mobile-menu .site-logo').click(function(){
      $('header .open-menu').removeClass('open');
      mobileMenu.removeClass('open');
    });
  },
  init() {
    // JavaScript to be fired on all pages    
    document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
    document.querySelector(':root').style.setProperty('--vhinit', window.innerHeight/100 + 'px');
    
    //cursor
    cursorLagWrapper = document.querySelector('.cursor-lag-wrapper');
    // const cursorLag = document.querySelector('.cursor-lag');
    cursorDot = document.querySelector('.cursor-dot');
    // const cursorInfo = document.querySelector('.cursor-info');
    cursorInfoWrapper = document.querySelector('.cursor-info-wrapper');

    var cursorTimeout;
    document.addEventListener('mousemove', e => {
      moveMagicCursor(e);
      if (cursorTimeout !== undefined) {
        window.clearTimeout(cursorTimeout);
      }

      cursorTimeout = window.setTimeout(function () {
        // trigger the new event on event.target, so that it can bubble appropriately
        $(document).trigger('mousemoveend', e);
      }, 100);
    });

    $(document).on('mousemoveend', function(event, e) {
      moveMagicCursor(e);
    });

    document.addEventListener('drag', e => {
      moveMagicCursor(e);
    });
    
    //detect click
    document.addEventListener('mousedown', function(e){
      $('.cursor-lag, .cursor-dot').addClass('clicking');
      moveMagicCursor(e);
    });

    document.addEventListener('mouseup', function(e){
      $('.cursor-lag, .cursor-dot').removeClass('clicking');
      moveMagicCursor(e);
    });

    document.addEventListener('mouseleave', function(){
      $(cursorDot).hide();
      $(cursorLagWrapper).hide();
    });

    // $('#meeting-popup').hover(function(){
    //   hideMagicCursor = true;
    //   $(cursorDot).hide();
    //   $(cursorLagWrapper).hide();
    // },function(){
    //   hideMagicCursor = false;
    //   $(cursorDot).show();
    //   $(cursorLagWrapper).show();
    // });

    var width = $(window).width();
    if (width <= 960) {
      //code for mobile devices
      isMobile = true;
    } else {
      //code for other devices
      isMobile = false;
    }

    scroll = new LocomotiveScroll({
      el: document.querySelector('[data-scroll-container]'),
      smooth: true,
      multiplier: 1,
      smartphone: {
        smooth: false,
      },
      tablet: {
        smooth: false,
      },
    });

    //background animation
    rendererSize = new Vector2($(window).width(), window.outerHeight);
    renderer = new WebGLRenderer({alpha: true, antialias: true });
    renderer.setSize( rendererSize.x, rendererSize.y );
    renderer.setClearColor( 0x000000, 0.0 );
    renderer.context.getExtension('OES_standard_derivatives');
    renderer.setPixelRatio(window.devicePixelRatio);
    $('.background-animation').append(renderer.domElement);

    camera = new PerspectiveCamera( 45, $(window).width() / window.outerHeight, 0.1, 1000 );

    clock = new Clock();

    scene = new Scene();
    scene2 = new Scene();

    blob1 = new Object3D();

    // function vertexShader() {
    //   return `
    //     varying vec3 vUv; 
    
    //     void main() {
    //       vUv = position; 
    
    //       vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
    //       gl_Position = projectionMatrix * modelViewPosition; 
    //     }
    //   `
    // }

    // function fragmentShader() {
    //     return `
    //     uniform vec3 colorA; 
    //     uniform vec3 colorB; 
    //     varying vec3 vUv;

    //     void main() {
    //       gl_FragColor = vec4(mix(colorA, colorB, vUv.z), 1.0);
    //     }
    //   `
    // }
    
    var blobSegments = 300;
    if(isMobile) {
      blobSegments = 150;
    }
    var segmentsY = blobSegments;
    var segmentsX = blobSegments * 1.33;
    const geometry = new SphereBufferGeometry(1, segmentsX, segmentsY);

    const material = new MeshPhysicalMaterial();
    material.onBeforeCompile = function(shader){

      shader.uniforms.time = {value: 0};
      shader.uniforms.radius = {value: 1};
      shader.uniforms.distort = {value: 0};
      shader.uniforms.speed = {value: 1.62};
      shader.uniforms.uNoiseDensity = {value: 1.0};
      shader.uniforms.uNoiseStrength = {value: 2.0};
      shader.uniforms.frequency = {value: 3.05};
      shader.uniforms.surfaceDistort = {value: 2.3};
      shader.uniforms.surfaceFrequency = {value: 1.5};
      shader.uniforms.surfaceTime = {value: 0};
      shader.uniforms.surfaceSpeed = {value: 0.65};
      shader.uniforms.numberOfWaves = {value: 4.8};
      shader.uniforms.fixNormals = {value: true};
      shader.uniforms.surfacePoleAmount = {value: 1.5};
      shader.uniforms.gooPoleAmount = {value: 1};

      shader.vertexShader = vertexShader;
      shader.fragmentShader = fragmentShader;

      material.userData.shader = shader;

      // if(enableDebugging){
      //   gui.add(blob1.children[0].material.userData.shader.uniforms.speed, 'value').name('speed');
      //   gui.add(blob1.children[0].material.userData.shader.uniforms.radius, 'value').name('radius');
      //   gui.add(blob1.children[0].material.userData.shader.uniforms.distort, 'value').name('distort');
      //   gui.add(blob1.children[0].material.userData.shader.uniforms.frequency, 'value').name('frequency');
      //   gui.add(blob1.children[0].material.userData.shader.uniforms.surfaceDistort, 'value').name('surfaceDistort');
      //   gui.add(blob1.children[0].material.userData.shader.uniforms.surfaceFrequency, 'value').name('surfaceFrequency');
      //   gui.add(blob1.children[0].material.userData.shader.uniforms.surfaceTime, 'value').name('surfaceTime');
      //   gui.add(blob1.children[0].material.userData.shader.uniforms.surfaceSpeed, 'value').name('surfaceSpeed');
      //   gui.add(blob1.children[0].material.userData.shader.uniforms.numberOfWaves, 'value').name('numberOfWaves');
      //   gui.add(blob1.children[0].material.userData.shader.uniforms.surfacePoleAmount, 'value').name('surfacePoleAmount');
      //   gui.add(blob1.children[0].material.userData.shader.uniforms.gooPoleAmount, 'value').name('gooPoleAmount');
      //   gui.add(blob1.children[0].material, 'clearcoat').name('clearcoat');
      //   gui.add(blob1.children[0].material, 'clearcoatRoughness').name('clearcoatRoughness');
      //   gui.add(blob1.children[0].material, 'roughness').name('roughness');
      //   gui.add(blob1.children[0].material, 'metalness').name('metalness');
      //   gui.add(blob1.children[0].material, 'reflectivity').name('reflectivity');
      // }
    };
    material.color.setHex(Number('0xd8e3fc'));
    material.lights = true;
    material.transparent = true;
    material.roughness = 0.8;
    material.metalness = 1;
    material.clearcoat = 1;
    material.clearcoatRoughness= 0.57;
    material.reflectivity = 0;
    material.ior = 1;

    const mesh = new Mesh(geometry, material);

    blob1.add(mesh);

    blob2 = blob1.clone();
    const material2 = new MeshPhysicalMaterial();
    material.color.setHex(Number('0xd8e3fc'));
    material.lights = true;
    material.transparent = true;
    material.roughness = 0.8;
    material.metalness = 1;
    material.clearcoat = 1;
    material.clearcoatRoughness= 0.57;
    material.reflectivity = 0;
    material.ior = 1;
    material2.onBeforeCompile = function(shader){
      shader.uniforms.time = {value: 0};
      shader.uniforms.radius = {value: 1};
      shader.uniforms.distort = {value: 0};
      shader.uniforms.speed = {value: 1.39};
      shader.uniforms.uNoiseDensity = {value: 1.0};
      shader.uniforms.uNoiseStrength = {value: 2.0};
      shader.uniforms.frequency = {value: 0.1};
      shader.uniforms.surfaceDistort = {value: 3};
      shader.uniforms.surfaceFrequency = {value: 1.53};
      shader.uniforms.surfaceTime = {value: 0};
      shader.uniforms.surfaceSpeed = {value: 0.98};
      shader.uniforms.numberOfWaves = {value: 2};
      shader.uniforms.fixNormals = {value: true};
      shader.uniforms.surfacePoleAmount = {value: 1};
      shader.uniforms.gooPoleAmount = {value: 1};

      shader.vertexShader = vertexShader;
      shader.fragmentShader = fragmentShader;

      material2.userData.shader = shader;

      // if(enableDebugging){
      //   gui.add(blob2.children[0].material.userData.shader.uniforms.speed, 'value').name('speed');
      //   gui.add(blob2.children[0].material.userData.shader.uniforms.radius, 'value').name('radius');
      //   gui.add(blob2.children[0].material.userData.shader.uniforms.distort, 'value').name('distort');
      //   gui.add(blob2.children[0].material.userData.shader.uniforms.frequency, 'value').name('frequency');
      //   gui.add(blob2.children[0].material.userData.shader.uniforms.surfaceDistort, 'value').name('surfaceDistort');
      //   gui.add(blob2.children[0].material.userData.shader.uniforms.surfaceFrequency, 'value').name('surfaceFrequency');
      //   gui.add(blob2.children[0].material.userData.shader.uniforms.surfaceTime, 'value').name('surfaceTime');
      //   gui.add(blob2.children[0].material.userData.shader.uniforms.surfaceSpeed, 'value').name('surfaceSpeed');
      //   gui.add(blob2.children[0].material.userData.shader.uniforms.numberOfWaves, 'value').name('numberOfWaves');
      //   gui.add(blob2.children[0].material.userData.shader.uniforms.surfacePoleAmount, 'value').name('surfacePoleAmount');
      //   gui.add(blob2.children[0].material.userData.shader.uniforms.gooPoleAmount, 'value').name('gooPoleAmount');
      //   gui.add(blob2.children[0].material, 'clearcoat').name('clearcoat');
      //   gui.add(blob2.children[0].material, 'clearcoatRoughness').name('clearcoatRoughness');
      //   gui.add(blob2.children[0].material, 'roughness').name('roughness');
      //   gui.add(blob2.children[0].material, 'metalness').name('metalness');
      //   gui.add(blob2.children[0].material, 'reflectivity').name('reflectivity');
      // }

      blob2.children[0].material = material2;
    };
    blob2.children[0].material = material2;

    scene.add(blob1);
    scene2.add(blob2);

    //LIGHTS
    //scene1

    //white
    const light1 = new SpotLight( 0xffffff );
    light1.position.set( 10, 10, 3.7 );
    light1.intensity = 0.93;
    light1.angle = 0.5;
    light1.penumbra = 1;
    scene.add(light1);
    scene.add(light1.target);
    light1.target = blob1;
    light1.target.updateMatrixWorld();

    //pink
    const light2 = new SpotLight( 0xfc71ff );
    light2.position.set( -1.09, 0, 1.38 );
    light2.intensity = 1.5;
    light2.angle = 0.5;
    light2.penumbra = 0.66;
    scene.add(light2);
    scene.add(light2.target);
    light2.target = blob1;
    light2.target.updateMatrixWorld();

    //lightblue
    const light3 = new SpotLight( 0x86cee5 );
    light3.position.set( 0.24, -0.44, 10.1 );
    light3.intensity = 1.5;
    light3.angle = 0.5;
    light3.penumbra = 1;
    scene.add(light3);
    scene.add(light3.target);
    light3.target = blob1;
    light3.target.updateMatrixWorld();

    //purple
    const light4 = new SpotLight( 0xa379ff);
    light4.position.set( 4.98, -3.59, 2.99 );
    light4.intensity = 1.51;
    light4.angle = 0.5;
    light4.penumbra = 1;
    scene.add(light4);
    scene.add(light4.target);
    light4.target = blob1;
    light4.target.updateMatrixWorld();

    //darkblue
    const light5 = new SpotLight( 0x2953ea );
    light5.position.set( -6.98, 3.62, -0.39 );
    light5.intensity = 1.36;
    light5.angle = 0.5;
    light5.penumbra = 1;
    scene.add(light5);
    scene.add(light5.target);
    light5.target = blob1;
    light5.target.updateMatrixWorld();

    //white2
    const light6 = new SpotLight( 0xffffff );
    light6.position.set( -2.69, -10, -0.39 );
    light6.intensity = 0.61;
    light6.angle = 0.5;
    light6.penumbra = 1;
    scene.add(light6);
    scene.add(light6.target);
    light6.target = blob1;
    light6.target.updateMatrixWorld();


    //scene2
    //white
    const light1_scene2 = new SpotLight( 0xffffff );
    light1_scene2.position.set( 1.5, 10.93, -1.3 );
    light1_scene2.intensity = 0.16;
    light1_scene2.angle = 0.5;
    light1_scene2.penumbra = 1;
    scene2.add(light1_scene2);
    scene2.add(light1_scene2.target);
    light1_scene2.target = blob2;
    light1_scene2.target.updateMatrixWorld();

    //pink
    const light2_scene2 = new SpotLight( 0xfc71ff );
    light2_scene2.position.set( -6.98, 0.93, -1.41 );
    light2_scene2.intensity = 0.38;
    light2_scene2.angle = 0.5;
    light2_scene2.penumbra = 0.38;
    scene2.add(light2_scene2);
    scene2.add(light2_scene2.target);
    light2_scene2.target = blob2;
    light2_scene2.target.updateMatrixWorld();

    //lightblue
    const light3_scene2 = new SpotLight( 0x86cee5 );
    light3_scene2.position.set( -10, 4.75, -0.05 );
    light3_scene2.intensity = 0.95;
    light3_scene2.angle = 0.5;
    light3_scene2.penumbra = 1;
    scene2.add(light3_scene2);
    scene2.add(light3_scene2.target);
    light3_scene2.target = blob2;
    light3_scene2.target.updateMatrixWorld();

    //purple
    const light4_scene2 = new SpotLight( 0xa379ff);
    light4_scene2.position.set( -3.52, -2.66, -2.01 );
    light4_scene2.intensity = 1.51;
    light4_scene2.angle = 0.5;
    light4_scene2.penumbra = 1;
    scene2.add(light4_scene2);
    scene2.add(light4_scene2.target);
    light4_scene2.target = blob2;
    light4_scene2.target.updateMatrixWorld();

    //darkblue
    const light5_scene2 = new SpotLight( 0x2953ea );
    light5_scene2.position.set(0.69, 4.6, -5.4 );
    light5_scene2.intensity = 1.17;
    light5_scene2.angle = 0.5;
    light5_scene2.penumbra = 1;
    scene2.add(light5_scene2);
    scene2.add(light5_scene2.target);
    light5_scene2.target = blob2;
    light5_scene2.target.updateMatrixWorld();

    //white2
    const light6_scene2 = new SpotLight( 0xffffff );
    light6_scene2.position.set( -4.05, 4.6, -5.4 );
    light6_scene2.intensity = 0.27;
    light6_scene2.angle = 0.5;
    light6_scene2.penumbra = 1;
    scene2.add(light6_scene2);
    scene2.add(light6_scene2.target);
    light6_scene2.target = blob2;
    light6_scene2.target.updateMatrixWorld();

    // const spotlightHelper1 = new SpotLightHelper(light1);
    // scene.add(spotlightHelper1);
    // if(enableDebugging){
    //   const spotlightHelper2 = new SpotLightHelper(light2);
    //   scene.add(spotlightHelper2);
    //   const spotlightHelper3 = new SpotLightHelper(light3);
    //   scene.add(spotlightHelper3);
    //   const spotlightHelper6 = new SpotLightHelper(light6);
    //   scene.add(spotlightHelper6);
    // }

    // const sphereSize = 1;
    // const pointLightHelper1 = new PointLightHelper( light1, sphereSize );
    // const pointLightHelper2 = new PointLightHelper( light2, sphereSize );
    // const pointLightHelper3 = new PointLightHelper( light3, sphereSize );
    // const pointLightHelper4 = new PointLightHelper( light4, sphereSize );
    // const pointLightHelper5 = new PointLightHelper( light5, sphereSize );
    // const pointLightHelper6 = new PointLightHelper( light6, sphereSize );
    // scene.add( pointLightHelper1 );
    // scene.add( pointLightHelper2 );
    // scene.add( pointLightHelper3 );
    // scene.add( pointLightHelper4 );
    // scene.add( pointLightHelper5 );
    // scene.add( pointLightHelper6 );

    // class ColorGUIHelper {
    //   constructor(object, prop) {
    //     this.object = object;
    //     this.prop = prop;
    //   }
    //   get value() {
    //     return `#${this.object[this.prop].getHexString()}`;
    //   }
    //   set value(hexString) {
    //     this.object[this.prop].set(hexString);
    //   }
    // }

    // if(enableDebugging){
    //   gui = new dat.GUI();
    //   const light1Folder = gui.addFolder('White');
    //   light1Folder.add(light1_scene2.position, 'x').min(-10).max(10).step(0.01);
    //   light1Folder.add(light1_scene2.position, 'y').min(-10).max(10).step(0.01);
    //   light1Folder.add(light1_scene2.position, 'z').min(-10).max(20).step(0.01);
    //   light1Folder.add(light1_scene2, 'intensity').min(0).max(10).step(0.01);
    //   light1Folder.addColor(new ColorGUIHelper(light1_scene2, 'color'), 'value').name('color');

    //   const light2Folder = gui.addFolder('Pink');
    //   light2Folder.add(light2_scene2.position, 'x').min(-10).max(10).step(0.01);
    //   light2Folder.add(light2_scene2.position, 'y').min(-10).max(10).step(0.01);
    //   light2Folder.add(light2_scene2.position, 'z').min(-10).max(20).step(0.01);
    //   light2Folder.add(light2_scene2, 'intensity').min(0).max(10).step(0.01);
    //   light2Folder.addColor(new ColorGUIHelper(light2_scene2, 'color'), 'value').name('color');

    //   const light3Folder = gui.addFolder('Light Blue');
    //   light3Folder.add(light3_scene2.position, 'x').min(-10).max(10).step(0.01);
    //   light3Folder.add(light3_scene2.position, 'y').min(-10).max(10).step(0.01);
    //   light3Folder.add(light3_scene2.position, 'z').min(-10).max(20).step(0.01);
    //   light3Folder.add(light3_scene2, 'intensity').min(0).max(10).step(0.01);
    //   light3Folder.addColor(new ColorGUIHelper(light3_scene2, 'color'), 'value').name('color');

    //   const light4Folder = gui.addFolder('Purple');
    //   light4Folder.add(light4_scene2.position, 'x').min(-10).max(10).step(0.01);
    //   light4Folder.add(light4_scene2.position, 'y').min(-10).max(10).step(0.01);
    //   light4Folder.add(light4_scene2.position, 'z').min(-10).max(20).step(0.01);
    //   light4Folder.add(light4_scene2, 'intensity').min(0).max(10).step(0.01);
    //   light4Folder.addColor(new ColorGUIHelper(light4_scene2, 'color'), 'value').name('color');

    //   const light5Folder = gui.addFolder('Dark Blue');
    //   light5Folder.add(light5_scene2.position, 'x').min(-10).max(10).step(0.01);
    //   light5Folder.add(light5_scene2.position, 'y').min(-10).max(10).step(0.01);
    //   light5Folder.add(light5_scene2.position, 'z').min(-10).max(20).step(0.01);
    //   light5Folder.add(light5_scene2, 'intensity').min(0).max(10).step(0.01);
    //   light5Folder.addColor(new ColorGUIHelper(light5_scene2, 'color'), 'value').name('color');

    //   const light6Folder = gui.addFolder('White Back');
    //   light6Folder.add(light6_scene2.position, 'x').min(-10).max(10).step(0.01);
    //   light6Folder.add(light6_scene2.position, 'y').min(-10).max(10).step(0.01);
    //   light6Folder.add(light6_scene2.position, 'z').min(-10).max(20).step(0.01);
    //   light6Folder.add(light6_scene2, 'intensity').min(0).max(10).step(0.01);
    //   light6Folder.addColor(new ColorGUIHelper(light6_scene2, 'color'), 'value').name('color');

    //   const cameraFolder = gui.addFolder('Camera Position');
    //   cameraFolder.add(camera.position, 'x').min(-20).max(20).step(0.01);
    //   cameraFolder.add(camera.position, 'y').min(-20).max(20).step(0.01);
    //   cameraFolder.add(camera.position, 'z').min(-20).max(20).step(0.01);
      
    //   const cameraFolderRotation = gui.addFolder('Camera Rotation');
    //   cameraFolderRotation.add(camera.rotation, 'x').min(-20).max(20).step(0.01);
    //   cameraFolderRotation.add(camera.rotation, 'y').min(-20).max(20).step(0.01);
    //   cameraFolderRotation.add(camera.rotation, 'z').min(-20).max(20).step(0.01);
    // }



    // const sphereSize = 1;
    // const pointLightHelper = new PointLightHelper( light, sphereSize );
    // scene.add( pointLightHelper );

    // const tl = gsap.timeline({
    //   delay: 0.25,
    // });

    //tl.add(animBlobs(), '-=1.5');

    cameraY = 0;
    cameraZ = 5.32;
    gsap.to(camera.position, { duration: gsapDuration, x: 0, y: 0, z: 5.32, ease: gsapEase });
    gsap.to(camera.rotation, {duration: gsapDuration, x: 0, y: 0, z: 0, ease: gsapEase });
    if(isMobile) {
      cameraY = -1;
      cameraZ = 9.32;
      gsap.to(camera.position, { duration: gsapDuration, x: 1.7, y: cameraY, z: 9.32, ease: gsapEase });
      gsap.to(camera.rotation, {duration: gsapDuration, x: 0.1, y: 0.2, z: -1.2708, ease: gsapEase });
    }
    // }
    // if($('body').hasClass('template-studio')){
    //   //studio page
    //   gsap.to(camera.position, {duration: gsapDuration, x: -4, y: 0, z: 4.32, ease: gsapEase });
    //   gsap.to(camera.rotation, {duration: gsapDuration, x: 0, y: -0.274533, z: 0, ease: gsapEase });
    //   if(isMobile) {
    //     gsap.to(camera.position, {duration: gsapDuration, x: -1.2, y: 0, z: 13.32, ease: gsapEase });
    //     gsap.to(camera.rotation, {duration: gsapDuration, x: 0, y: 0, z: 0.7, ease: gsapEase });
    //   }
    if($('body').hasClass('template-contact')){
      //contact page
      cameraZ = 5.32;
      gsap.to(camera.position, { duration: gsapDuration, x: -1, y: 0, z: cameraZ, ease: gsapEase });
      gsap.to(camera.rotation, {duration: gsapDuration, x: -0.223599, y: 0, z: 0, ease: gsapEase });
      if(isMobile) {
        cameraZ = 10.32;
        gsap.to(camera.position, {duration: gsapDuration, x: -1.7, y: -0.5, z: cameraZ, ease: gsapEase });
        gsap.to(camera.rotation, {duration: gsapDuration, x: 0, y: 0.1, z: 1.8, ease: gsapEase });
      }
    }else if($('body').hasClass('template-projects') || $('body').hasClass('single-project') || $('body').hasClass('error404')){
      gsap.to(camera.position, {duration: gsapDuration, x:2.0, y: -0.7, z: 5.40, ease: 'elastic.out(1, 1)' });
      gsap.to(camera.rotation, {duration: gsapDuration, x: 0, y: 0, z: 0, ease: gsapEase });
      if(isMobile) {
        gsap.to(camera.position, {duration: gsapDuration, x:2.0, y: -0.6, z: 10.20, ease: 'elastic.out(1, 1)' });
      }
    }

    renderer.render(scene, camera);

    this.containerInit();

    const fpsInterval = 1000 / 60;
    var lastFrame= 0;
    function animate(){
      setTimeout(function(){
        requestAnimationFrame( animate );
      }, 1000 / 30);
      // requestAnimationFrame( animate );

      // if( enableDebugging){
      //   orbitControls.update();
      // }

      const now = clock.getElapsedTime() * 1000;
      const delta = now - lastFrame;
      lastFrame = now - (delta % fpsInterval);

      blob1.children[0].material.userData.shader.uniforms.time.value += 0.001 * 0.5 * delta * blob1.children[0].material.userData.shader.uniforms.speed.value;
      blob1.children[0].material.userData.shader.uniforms.surfaceTime.value += 0.001 * 0.5 * delta * blob1.children[0].material.userData.shader.uniforms.surfaceSpeed.value;
      if(typeof blob2.children[0].material.userData.shader !== 'undefined'){
        blob2.children[0].material.userData.shader.uniforms.time.value += 0.001 * 0.5 * delta * blob2.children[0].material.userData.shader.uniforms.speed.value;
        blob2.children[0].material.userData.shader.uniforms.surfaceTime.value += 0.001 * 0.5 * delta * blob2.children[0].material.userData.shader.uniforms.surfaceSpeed.value;  
      }

      renderer.autoClear = false;
      renderer.clear();
      renderer.render(scene,camera);

      renderer.render(scene2,camera);
    }
    animate();

    //rotate blobs with mousemovement
    var mouse = {
      x: 0,
      y: 0,
    };
    document.addEventListener('mousemove', onMouseMove, false);
    function onMouseMove(event){
      event.preventDefault();
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      gsap.to(blob1.rotation, {duration: gsapDuration, x: -mouse.y, y: mouse.x, z: 0, ease: gsapEase });
      gsap.to(blob2.rotation, {duration: gsapDuration, x: -mouse.y, y: mouse.x, z: 0, ease: gsapEase });
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
  transition(page){
    gsap.to(blob1.position, {duration: 5,x:blob1Position.x, y: blob1Position.y, z: blob1Position.z, ease: gsapEase });
    if(page=='home' || page=='studio'){
      gsap.to(camera.position, { duration: gsapDuration, x: 0, y: 0, z: 5.32, ease: gsapEase });
      gsap.to(camera.rotation, {duration: gsapDuration, x: 0, y: 0, z: 0, ease: gsapEase });  
      if(isMobile) {
        gsap.to(camera.position, { duration: gsapDuration, x: 1.7, y: -1, z: 9.32, ease: gsapEase });
        gsap.to(camera.rotation, {duration: gsapDuration, x: 0.1, y: 0.2, z: -1.2708, ease: gsapEase });
      }
    // }
    // else if(page=='studio'){
    //   gsap.to(camera.position, {duration: gsapDuration, x: -4, y: 0, z: 4.32, ease: gsapEase });
    //   gsap.to(camera.rotation, {duration: gsapDuration, x: 0, y: -0.274533, z: 0, ease: gsapEase });
    //   if(isMobile) {
    //     gsap.to(camera.position, {duration: gsapDuration, x: -1.2, y: 0, z: 13.32, ease: gsapEase });
    //     gsap.to(camera.rotation, {duration: gsapDuration, x: 0, y: 0, z: 0.7, ease: gsapEase });
    //   }
    }else if(page=='projects'){
      gsap.to(camera.position, {duration: gsapDuration, x:2.0, y: -0.7, z: 5.40, ease: 'elastic.out(1, 1)' });
      gsap.to(camera.rotation, {duration: gsapDuration, x: 0, y: 0, z: 0, ease: gsapEase });
      if(isMobile) {
        gsap.to(camera.position, {duration: gsapDuration, x:2.0, y: -0.6, z: 10.20, ease: 'elastic.out(1, 1)' });
      }
    }else if(page=='contact'){
      gsap.to(camera.position, { duration: gsapDuration, x: -1, y: 0, z: 5.32, ease: gsapEase });
      gsap.to(camera.rotation, {duration: gsapDuration, x: -0.223599, y: 0, z: 0, ease: gsapEase });
      if(isMobile) {
        gsap.to(camera.position, {duration: gsapDuration, x: -1.7, y: -0.5, z: 10.32, ease: gsapEase });
        gsap.to(camera.rotation, {duration: gsapDuration, x: 0, y: 0.1, z: 1.8, ease: gsapEase });
      }
    }
  },
};

