/* eslint-disable no-use-before-define */

import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import { gsap } from 'gsap';
import common from './routes/common';
import studio from './routes/studio';
import home from './routes/home';
import projects from './routes/projects';

var isMobile;

export default function () {
  // barba.hooks.beforeEnter(({ current, next }) => {
  //   // Set <body> classes for 'next' page
  //   if (current.container) {
  //     let nextHtml = next.html;
  //     let response = nextHtml.replace(
  //       /(<\/?)body( .+?)?>/gi,
  //       '$1notbody$2>',
  //       nextHtml
  //     );
  //     let bodyClasses = $(response).filter('notbody').attr('class');
  //     $('body').attr('class', bodyClasses);

  //   }
  // });

  barba.use(barbaPrefetch);

  function defaultEnter(){
    $('body').attr('class', $('#body-classes').attr('class'));
    $('body').css('overflow', 'unset');
    common.containerInit();
    scroll.update();
    scroll.scrollTo('top', { duration: 0, disableLerp: true });
    window.scrollTo(0, 0);
    setTimeout(() => {
      scroll.update();
    }, 1000);
  }

  function defaultLeave(){
    window.scrollTo(0, 0);
    $('.cursor-info').removeClass('showing medium large');
    $('.cursor-lag, .cursor-dot').removeClass('hovering');
  }

  var width = $(window).width();
  if (width <= 960) {
    //code for mobile devices
    isMobile = true;
  } else {
    //code for other devices
    isMobile = false;
  }

  $.post(ajax_object.ajax_url, {action: 'is_user_logged_in'}, function (isLogged) {
    if (!isLogged) barba.init({
      timeout: 10000,
      debug: false,
      transitions: [
        {
          name: 'default-transition',
          sync: false,
          leave: function(data){
            gsap.to(data.current.container, 0.5, {opacity: '0', ease:'expo.inOut', onComplete: this.async()});
            defaultLeave();
          },
          enter: function(data){
            // data.current.container.parentNode.removeChild(data.current.container);
            setTimeout(() => {
              $('body').attr('class', $('#body-classes').attr('class'));
            }, 0);
            gsap.from(data.next.container, 0.5, {opacity: '0', ease:'expo.inOut', onComplete: () => {
              this.async();
              defaultEnter();
            }});
          },
        },
        {
          name: 'studio',
          to: {
            namespace: ['studio'],
          },
          leave: function(data){
            common.transition('studio');
            defaultLeave();
            gsap.to(data.current.container, 0.5, {opacity: '0', ease:'expo.inOut', onComplete: this.async()});
          },
          enter: function(data){
            gsap.from(data.next.container, 0.5, {opacity: '0', ease:'expo.inOut', onComplete: () => {
              this.async();              
              defaultEnter();
              studio.init();
            }});
          },
        },
        {
          name: 'home',
          to: {
            namespace: ['home'],
          },
          leave: function(data){
            common.transition('home');
            defaultLeave();
            gsap.to(data.current.container, 0.5, {opacity: '0', ease:'expo.inOut', onComplete: this.async()});
          },
          enter: function(data){
            gsap.from(data.next.container, 0.5, {opacity: '0', ease:'expo.inOut', onComplete: () => {
              this.async();
              defaultEnter();
              home.init();
            }});
          },
        },
        {
          name: 'projects',
          to: {
            namespace: ['projects'],
          },
          leave: function(data){
            common.transition('projects');
            defaultLeave();
            gsap.to(data.current.container, 0.5, {opacity: '0', ease:'expo.inOut', onComplete: this.async()});
          },
          enter: function(data){
            setTimeout(() => {
              $('body').attr('class', $('#body-classes').attr('class'));
            }, 0);
            gsap.from(data.next.container, 1.3, {opacity: '0', ease:'expo.inOut', onComplete: () => {
              this.async();
              defaultEnter();
              projects.init();
            }});
          },
        },
        {
          name: 'project',
          to: {
            namespace: ['project'],
          },
          sync: false,
          leave: function(data){
            defaultLeave();
            gsap.to(data.current.container, 0.6, {opacity: '0', onComplete: this.async()});
          },
          enter: function(data){
            setTimeout(() => {
              $('body').attr('class', $('#body-classes').attr('class'));
            }, 0);

            // defaultEnter();

            console.log('transitioniinggg');


            if(typeof data.trigger.classList !== 'undefined'){
              gsap.fromTo(data.next.container, 1, 
                {opacity: '1', width: '100vw', height: '100vh', left: 0, top: '100vh', position: 'absolute', overflow: 'hidden'},
                {opacity: '1', width: '100vw', height: '100vh', left: 0, top: 0, position: 'relative', ease: 'expo.inOut', onComplete: () => {
                  $(data.next.container).css('height', 'auto');
                  $(data.next.container).css('width', 'auto');
                  $(data.next.container).css('overflow', 'visible');              
                this.async();
                defaultEnter();
              }});
            }else {
              gsap.from(data.next.container, 1, {opacity: '0', ease:'expo.inOut', onComplete: () => {
                $(data.next.container).css('height', 'auto');
                $(data.next.container).css('width', 'auto');
                $(data.next.container).css('overflow', 'visible');   
                this.async();
                defaultEnter();
              }});
            }
          },
        },
        {
          name: 'project',
          to: {
            namespace: ['project'],
          },
          from: {
            namespace: ['project'],
          },
          sync: false,
          leave: function(){
            defaultLeave();
            // gsap.to(data.current.container, 0, {opacity: '0', onComplete: this.async()});
          },
          enter: function(data){
            setTimeout(() => {
              $('body').attr('class', $('#body-classes').attr('class'));
            }, 0);

            scroll.scrollTo('top', { duration: 0, disableLerp: true });

            var classes = '';
            // defaultEnter();

            if(typeof data.trigger.classList !== 'undefined'){
              data.trigger.classList.forEach(classString => {
                classes += '.';
                classes += classString;
              });
  
              var dataAttribute = '[data-index="'+data.trigger.dataset.index+'"]';
  
              var triggerElement = $(classes+dataAttribute);
              var height = triggerElement.innerHeight();
              var width = triggerElement.innerWidth();
              var left = triggerElement.offset().left;
              var top = triggerElement.offset().top;
              if(isMobile) {
                top = $(window).height() - triggerElement.innerHeight();
              }

              gsap.fromTo(data.next.container, 1, 
                {opacity: '1', width: width, height: height, left: left, top: top, position: 'absolute', overflow: 'hidden'},
                {opacity: '1', width: '100vw', height: '100vh', left: 0, top: 0, position: 'relative', ease: 'expo.inOut', onComplete: () => {
                  $(data.next.container).css('height', 'auto');
                  $(data.next.container).css('width', 'auto');
                  $(data.next.container).css('overflow', 'visible');              
                this.async();
                defaultEnter();
              }});

            }else {
              gsap.from(data.next.container, 1, {opacity: '0', ease:'expo.inOut', onComplete: () => {
                $(data.next.container).css('height', 'auto');
                $(data.next.container).css('width', 'auto');
                $(data.next.container).css('overflow', 'visible');   
                this.async();
                defaultEnter();
              }});
            }
          },
        },
        {
          name: 'contact',
          to: {
            namespace: ['contact'],
          },
          leave: function(data){
            common.transition('contact');
            defaultLeave();
            gsap.to(data.current.container, 0.5, {opacity: '0', ease:'expo.inOut', onComplete: this.async()});
          },
          enter: function(data){
            gsap.from(data.next.container, 0.5, {opacity: '0', ease:'expo.inOut', onComplete: () => {
              this.async();
              defaultEnter();
            }});
          },
        },
        
      ],
    });
  });
}