import Dropzone from 'dropzone/dist/dropzone';
Dropzone.autoDiscover = false;

export default {
  init() {
    // JavaScript to be fired on the about us page
    //create file dropzone
    var documentName;
    var documentId;
    var documentUrl;

    var documentDropzone = new Dropzone('#document-dropzone', {
      url: ajax_object.upload,
      success: function (file, response) {
        console.log(file);
        console.log(file.type);
        console.log(response);

        var responseJson = JSON.parse(response);
        file.previewElement.classList.add('dz-success');
        jQuery('#document').val(responseJson.id);
        documentName = file.name;
        documentId = responseJson.id;
        documentUrl = responseJson.url;

        console.log(documentName);
        console.log(documentId);
        console.log(documentUrl);

        $('#cv').val(documentUrl);
      },
      error: function (file) {
        file.previewElement.classList.add('dz-error');
      },
      addRemoveLinks: true,
        removedfile: function(file) {
        var attachment_id = file.attachment_id;        
        jQuery.ajax({
          type: 'POST',
          url: ajax_object.delete,
          data: {
              media_id : attachment_id,
          },
        });
        var _ref;
        return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;        
      },
      maxFiles: 1,
      dictDefaultMessage: 'Drop document here to upload',
    });

    console.log(documentDropzone);


    $('form').validate({
      ignore: ':hidden:not([class~=selectized], #document),:hidden > .selectized, .selectize-control .selectize-input input',
      rules: {
        cv: {
          required: {
            depends: function(){
              return ($('#cv').val().trim().length == 0);
            },
          },
        },
        address_1: 'required',
        city: 'required',
        post_code: 'required',
        country: 'required',
        opt_in: 'required',
        messages: {
          address_1: 'Please enter your address',
          city: 'Please enter your City',
          post_code: 'Please enter a valid Post Code',
        },
      },
    });
  },
  finalize(){
    
  },
};