// import external dependencies
import 'jquery';

// Import everything from autoload

import 'tilt.js';
import 'jquery-visible';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.js';
import 'panzoom/dist/panzoom';
import 'imagesloaded';
import 'jquery-validation/dist/jquery.validate';
import barbaInit from './page-transitions';

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import studio from './routes/studio';
import projects from './routes/projects';
import singleCareer from './routes/singleCareer';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  //Studio Page
  studio,
  //Projects Page
  projects,
  singleCareer,
});

// Load Events
jQuery(document).ready(() => {
  routes.loadEvents();
  barbaInit(routes);
});
